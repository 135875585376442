import React from 'react';
import ReactDOM from 'react-dom';
import { Alert, message, notification } from 'antd';
import { createRoot } from 'react-dom/client';

/**
 * Create an alert
 * @param message the message to display
 * @param type the type of the message: "success" | "info" | "warning" | "error" | undefined
 * @param showIcon if the icon must be visible
 * @param description an additional text
 */

export const alert = (
    message: string, 
    type: "success" | "info" | "warning" | "error" | undefined, 
    showIcon: boolean = true, 
    description?: string
  ) => {
    notification.open({
        message: message,
        description:description,
        type: type,
        placement: "bottomRight"
      });
  };

/**
 * Display an error message
 * @param msg the message to display
 * @param shakeId the id of the component to shake
 */
export const displayErrorMessage = (msg: string, shakeId?: string): void => {
    const component = shakeId ? document.getElementById(shakeId) : undefined;
    if (component && !component.className.includes("shake-animation")) {
        component.className += " shake-animation";
        setTimeout(() => component.className = component.className.replace(" shake-animation", ""), 1100);
    }
    message.error(msg, 6);
}