import React, { Fragment } from 'react';
import { RouterProps, BackofficeCustomer, BackofficeField, BackofficeCompany, BackofficeAddCustomer, Country, Canton } from '../../../utils/types';
import { Table, Typography, Checkbox, Input, Button, Tooltip, DatePicker, Modal, Row, Col, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ExclamationCircleOutlined, DownloadOutlined, BellOutlined, CheckOutlined, DeleteOutlined, PlusOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Network from '../../../utils/network';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { alert } from '../../../utils/utils';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import 'moment/locale/fr';
import _, { cloneDeep, toInteger } from 'lodash';
import { withRouter } from '../../../utils/withRouter';

interface IProps { }

type Props = IProps & RouterProps & WrappedComponentProps;

interface State {
    customers: BackofficeCustomer[] | undefined;
    company?: BackofficeCompany;
    searchedCustomers: BackofficeCustomer[] | undefined;
    customersToPrint: BackofficeCustomer[] | undefined;
    year: Moment;
    search: string;
    modalVisible: boolean;
    loading: boolean;
    fields?: BackofficeField[],
    modalEdit: boolean;
    addCustomer?: BackofficeAddCustomer;
    countries?: Country[];
    canton?: Canton[];
    loadingAdd: boolean;
}

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

/**
 * Component that represent the administration page
 */
class Administration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            customers: undefined,
            company: undefined,
            searchedCustomers: undefined,
            customersToPrint: undefined,
            year: moment().startOf('year'),
            search: "",
            modalVisible: false,
            modalEdit: false,
            loading: true,
            loadingAdd: false,
        };
    }
    showConfirm(customtitle: string, func: Function, record: any, e?: any) {
        const { confirm } = Modal;
        confirm({
            title: customtitle,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                e ? func(e, record) : func(record);
            },
            onCancel() {
                return false;
            },
        });
    }

    userModal(record: BackofficeCustomer) {
        let iban = true;
        return (
            <>
                <div style={{ marginLeft: '45px' }}>
                    {
                        record.modified && record.modified.length > 0 && <p>Vous avez modifié cet achat le {moment(record.modified).format("DD.MM.YYYY à HH:mm")}.</p>
                    }
                </div>
                <div className="backoffice-customer-info">
                    <p className="backoffice-customer-info-line" key={`full-name-${record.id}`}> <span style={{ fontWeight: "bold" }}>{this.props.intl.formatMessage({ id: "administration.name" })}: </span>{record.firstName + " " + record.lastName}</p>
                    <p className="backoffice-customer-info-line" key={`email-${record.id}`}> <span style={{ fontWeight: "bold" }}>{this.props.intl.formatMessage({ id: "administration.email" })}: </span>{record.email}</p>
                    {
                        record.fields && record.fields.map((fieldTMP: BackofficeField) => {
                            if (fieldTMP.displayName === "N° de transaction") iban = false;
                            if (fieldTMP.name === "canton") {
                                let canton = this.state.canton?.find(c => c.id === toInteger(fieldTMP.value));
                                if (canton) {
                                    let lang = this.props.router.params.lang;
                                    let cantonName = canton.translations.find(t => t.lang === lang);
                                    if (cantonName) {
                                        return <p className="backoffice-customer-info-line" key={"fieldValue-" + fieldTMP.id}> <span style={{ fontWeight: "bold" }}>{fieldTMP.displayName} : </span> {cantonName.name}</p>;
                                    }

                                }
                                return <p className="backoffice-customer-info-line" key={"fieldValue-" + fieldTMP.id}> <span style={{ fontWeight: "bold" }}>{fieldTMP.displayName} : </span> {fieldTMP.value}</p>;
                            } else if (fieldTMP.name === "country") {
                                let country = this.state.countries?.find(c => c.id === toInteger(fieldTMP.value));
                                if (country) {
                                    let lang = this.props.router.params.lang;
                                    let countryName = country.translations.find(t => t.lang === lang);
                                    if (countryName) {
                                        return <p className="backoffice-customer-info-line" key={"fieldValue-" + fieldTMP.id}> <span style={{ fontWeight: "bold" }}>{fieldTMP.displayName} : </span> {countryName.name}</p>;
                                    }

                                }
                            }

                            return <p className="backoffice-customer-info-line" key={"fieldValue-" + fieldTMP.id}> <span style={{ fontWeight: "bold" }}>{fieldTMP.displayName} : </span> {fieldTMP.value}</p>;
                        })
                    }

                    <p className="backoffice-customer-info-line" key={`payment-type-${record.id}`}> <span style={{ fontWeight: "bold" }}>Paiement : </span> {iban ? "IBAN" : "VISA"}</p>
                </div>
            </>
        );
    }

    userModalSckiClubCM(record: BackofficeCustomer) {
        return (
            <div className="backoffice-customer-info">
                {
                    record.fields && record.fields.map((fieldTMP: BackofficeField) => {
                        if (fieldTMP.displayName === "Facture") {
                            return <div style={{ width: '100%' }} key={"fieldValue-" + fieldTMP.id}>{<span dangerouslySetInnerHTML={{ __html: fieldTMP.value }} />}</div>;
                        } else {
                            return <p className="backoffice-customer-info-line" key={"fieldValue-" + fieldTMP.id}> <span style={{ fontWeight: "bold" }}>{fieldTMP.displayName} : </span> {fieldTMP.value}</p>;

                        }
                    })
                }
            </div>
        );
    }

    componentDidMount() {
        // let element: HTMLImageElement;
        // if (document && document.getElementById('__header-logo')) {
        //     element = document.getElementById('__header-logo').src="images/my_other_image.png";
        // }

        this.refresh();
    }

    refresh = () => {
        this.setState({ loading: true, modalEdit: false, addCustomer: undefined, loadingAdd: false });
        Network.getCompanyInfo().then(data => this.setState({ company: data }, () => {
            if (document.getElementById('__header-logo') && document.getElementById('__header-logo') != null && this.state.company) {
                document.getElementById('__header-logo')!.setAttribute('src', this.state.company.logo);
                document.title = "WebEvolutions: " + this.state.company.name;
            }
        }));
        Network.getCustomersList().then(data => this.setState({ customers: data, loading: false }, () => {
            if (this.state.search.length > 0) this.onSearch(this.state.search);
        }));
        Network.getFields().then(
            response => this.setState({ fields: response }),
            // error => alert("Impossible de charger les champs de Terraxis", "error"),
        );

        let lang = this.props.router.params.lang;
        Network.getCountriesList(lang).then(data => this.setState({ countries: data.data }));
        Network.getCantonList(lang).then(data => this.setState({ canton: data.data }));
        Network.getCountriesList(lang).then(data => this.setState({ countries: data.data }));
        Network.getCantonList(lang).then(data => this.setState({ canton: data.data }));
    };

    onChange = (e: CheckboxChangeEvent, record: BackofficeCustomer) => {
        Network.changePaymentState(record.id, e.target.checked)
            .then(() => this.refresh())
            .catch(() => alert("Un probleme", "error"));
    };

    invoiceReminderMail = (record: BackofficeCustomer) => {
        Network.backofficeSendIbanToCustomer("sendIban", record.lang, record.id, "True").then(data => {
            if (data.result === "ibanSent") {
                alert("Le rappel de paiement a été envoyé à l'adresse " + record.email + ".", "success");
                this.refresh();
            } else if (data.result === "ibanError") {
                alert("L'e-mail avec les informations de paiement n'a pas été envoyé.", "error");
            } else if (data.result === "customerPaid") {
                alert("Impossible d'envoyer le rappel de paiement car le client a déjà payé.", "warning");
            } else {
                alert("L'e-mail avec les informations de paiement n'a pas été envoyé.", "error");
            }
        });
    };

    deleteCustomer = (record: BackofficeCustomer) => {
        Network.deleteCustomer(record.id).then(data => {
            if (data.status && data.status === "customer deleted") {
                alert("Achat supprimé correctement.", "success");
                let newCustomers = cloneDeep(this.state.customers);
                newCustomers = newCustomers?.filter(c => c.id !== record.id);
                this.setState({ customers: newCustomers });
            } else if (data.error && data.error === "customer not exist") {
                alert("Achat introuvable.", "error");
            } else {
                alert("Erreur inconnue.", "error");

            }
        });
    };

    pyementConfirmationMail = (record: BackofficeCustomer) => {
        Network.sendPaymentConfirmation("sendConfirmMail", record.lang, record.id).then(data => {
            if (data.result === "mailConfirmationSuccess") {
                alert("Un e-mail de confirmation de paiement a été envoyé à l'adresse " + record.email + ".", "success");
            } else if (data.result === "mailConfirmationSuccess") {
                alert("L'e-mail de confirmation de paiement n'a pas été envoyé.", "error");
            } else if (data.result === "customerNotPaid") {
                alert("Impossible d'envoyer la confirmation de paiement car le client n'a pas payé.", "warning");
            } else {
                alert("L'e-mail de confirmation de paiement n'a pas été envoyé.", "error");
            }
        });

    };

    downloadCertificate = (record: BackofficeCustomer) => {
        Network.downloadCertificate(record.lang, record.id).then(data => {
            if (data.result === "incorrectRequest") {
                alert("Corruption de la requête lors de l'envoi au serveur", "error");
            } else if (data.result === "customerNotPaid") {
                alert("Impossible de télécharger le certificat car le client n'a pas payé.", "warning");
            } else if (data.result === "customerNotExist") {
                alert("Client introuvable.", "error");
            } else if (data.result === "pdfError") {
                alert("La génération du certificat a échoué.", "error");
            } else {
                FileSaver.saveAs(data, record.email + "-terraxis-certificate.pdf");
                //download(data, record.email + "-terraxis-certificate.pdf", "application/pdf")
            }
        });
    };

    generateLink = (record: BackofficeCustomer) => {
        Network.generateLink(record.id).then(
            data => {
                navigator.clipboard.writeText(`https://affiliation.terraxis.ch/fr/registration?id=${data.id}&token=${data.token}`);
                alert("Email de renouvellement envoyé. Le lien à été copié dans le presse-papiers.", "success");

            },
            error => console.log("Errror", error)
        );
    };

    doNothingTMP = (record: BackofficeCustomer) => {
        console.log("TMP", record);
    };

    columnsSkiClubCM = (): ColumnsType<BackofficeCustomer> => [
        {
            title: this.props.intl.formatMessage({ id: "administration.invoice" }),
            key: 'invoiceId',
            fixed: 'left',
            render: (_, record) => {
                let invoiceField = record.fields.find(f => f.name === "transaction_id");
                return invoiceField ? invoiceField.value : "-";
            },
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.name" }),
            key: 'customerName',
            render: (record) => record.firstName + " " + record.lastName,
            sorter: (a: BackofficeCustomer, b: BackofficeCustomer) => a.fields[0].value.localeCompare(b.fields[0].value),
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.email" }),
            dataIndex: 'email',
            key: 'customerEmail',
            sorter: (a: BackofficeCustomer, b: BackofficeCustomer) => a.fields[0].value.localeCompare(b.fields[0].value),
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.lang" }),
            dataIndex: 'lang',
            key: 'lang',
            width: '100px',
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.registeredDate" }),
            key: 'created',
            width: '160px',
            render: (record) => record.paid ? moment(record.created).format("DD.MM.YYYY") : <span>{moment(record.created).format("DD.MM.YYYY")} (<span style={{ fontWeight: 'bold', color: moment().diff(moment(record.created), "days") > 14 ? moment().diff(moment(record.created), "days") > 30 ? 'red' : 'orange' : '#CCCC00' }}>{moment().diff(moment(record.created), "days")}</span>)</span>
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.paidDate" }),
            key: 'paidDate',
            width: '160px',
            render: (record) => (
                record.paidDate && moment(record.paidDate).format("DD.MM.YYYY")
            )
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.sendMail" }),
            key: 'operation-confirmation-mail',
            fixed: 'right',
            width: '160px',
            render: (record) =>
                <div className="flex-space-evently">
                    <Tooltip title={"Télécharger"}>
                        <Button disabled={!record.paid} style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => this.doNothingTMP(record)} icon={
                            <span>
                                <DownloadOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                    <Tooltip title={"Confirmation de paiement"}>
                        <Button disabled={!record.paid} style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => this.showConfirm("Voulez-vous envoyer un email de confirmation de paiement?", this.doNothingTMP, record)} icon={
                            <span>
                                <CheckOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                    <Button style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => this.showConfirm('Voulez-vous supprimer cet achat?', this.deleteCustomer, record)} icon={
                        <span>
                            <DeleteOutlined />
                        </span>
                    } shape="circle" />
                </div>,
        },
    ];

    columns = (): ColumnsType<BackofficeCustomer> => [
        {
            title: this.props.intl.formatMessage({ id: "administration.companyName" }),
            key: 'companyName',
            fixed: 'left',
            render: (record: BackofficeCustomer) => {
                let field = record.fields.find(f => f.name === "company_name");
                return field ? field.value : "-";
            },
            sorter: (a: BackofficeCustomer, b: BackofficeCustomer) => {
                let fieldsA = a.fields.find(f => f.name === "company_name");
                let fieldsB = b.fields.find(f => f.name === "company_name");
                let fieldA = fieldsA?.value ? fieldsA.value : "Affiliation 2021";
                let fieldB = fieldsB?.value ? fieldsB.value : "Affiliation 2021";
                return fieldA.localeCompare(fieldB);
            },
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.product" }),
            key: 'product',
            fixed: 'left',
            width: '170px',
            render: (record: BackofficeCustomer) => {
                let field = record.fields.findIndex(f => f.name === "product");
                return field === -1 ? "Affiliation 2021" : record.fields[field].value;
            },
            sorter: (a: BackofficeCustomer, b: BackofficeCustomer) => {
                let fieldsA = a.fields.find(f => f.name === "product");
                let fieldsB = b.fields.find(f => f.name === "product");
                let fieldA = fieldsA?.value ? fieldsA.value : "Affiliation 2021";
                let fieldB = fieldsB?.value ? fieldsB.value : "Affiliation 2021";
                return fieldA.localeCompare(fieldB);
            },
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.lang" }),
            dataIndex: 'lang',
            key: 'lang',
            width: '100px',
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.registeredDate" }),
            key: 'created',
            width: '160px',
            render: (record) => record.paid ? moment(record.created).format("DD.MM.YYYY") : <span>{moment(record.created).format("DD.MM.YYYY")} (<span style={{ fontWeight: 'bold', color: moment().diff(moment(record.created), "days") > 14 ? moment().diff(moment(record.created), "days") > 30 ? 'red' : 'orange' : '#CCCC00' }}>{moment().diff(moment(record.created), "days")}</span>)</span>
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.lastReminder" }),
            key: 'lastReminder',
            width: '160px',
            render: (record) => record.paid ? record.lastReminder ? moment(record.lastReminder).format("DD.MM.YYYY") : '' : record.lastReminder ? <span>{moment(record.lastReminder).format("DD.MM.YYYY")} (<span style={{ fontWeight: 'bold', color: moment().diff(moment(record.lastReminder), "days") > 14 ? moment().diff(moment(record.lastReminder), "days") > 30 ? 'red' : 'orange' : '#CCCC00' }}>{moment().diff(moment(record.lastReminder), "days")}</span>)</span> : ''
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.paidDate" }),
            key: 'paidDate',
            width: '160px',
            render: (record) => (
                record.paidDate && moment(record.paidDate).format("DD.MM.YYYY")
            )
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.paid" }),
            key: 'operation-paid',
            width: '70px',
            render: (record) => <Checkbox checked={record.paid} onChange={(e) => this.showConfirm(record.paid === true ? "Confirmer que la société n'a pas encore payé?" : "Confirmer que la société a payé?", this.onChange, record, e)} />,
        },
        {
            title: this.props.intl.formatMessage({ id: "administration.sendMail" }),
            key: 'operation-confirmation-mail',
            fixed: 'right',
            width: '230px',
            render: (record) =>
                <div className="flex-space-evently">
                    <Tooltip title={"Générer lien"}>
                        <Button style={{ marginRight: '2px' }} onClick={() => this.generateLink(record)} icon={
                            <span>
                                <LinkOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                    <Tooltip title={"Télécharger"}>
                        <Button disabled={!record.paid} style={{ marginRight: '2px' }} onClick={() => this.downloadCertificate(record)} icon={
                            <span>
                                <DownloadOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                    <Tooltip title={"Confirmation de paiement"}>
                        <Button disabled={!record.paid} style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => this.showConfirm("Voulez-vous envoyer un email de confirmation de paiement?", this.pyementConfirmationMail, record)} icon={
                            <span>
                                <CheckOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                    <Tooltip title={"Rappel de paiement"}>
                        <Button disabled={record.paid} style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => this.showConfirm('Voulez-vous envoyer un email de rappel de paiement?', this.invoiceReminderMail, record)} icon={
                            <span>
                                <BellOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                    <Button style={{ marginLeft: '2px', marginRight: '2px' }} onClick={() => this.showConfirm('Voulez-vous supprimer cet achat?', this.deleteCustomer, record)} icon={
                        <span>
                            <DeleteOutlined />
                        </span>
                    } shape="circle" />
                    <Tooltip title={"Modifier"}>
                        <Button style={{ marginLeft: '2px' }} onClick={() => this.setState({ modalEdit: true, addCustomer: _.cloneDeep(record) })} icon={
                            <span>
                                <EditOutlined />
                            </span>
                        } shape="circle" />
                    </Tooltip>
                </div>,
        },
    ];

    /**
     * Handle customers filtering when using the search bar
     * @param search the string search
     */
    onSearch = (search: string) => {
        if (search.length === 0) {
            this.setState({ searchedCustomers: undefined, search });
        } else {
            let searchedCustomers = this.state.customers?.filter(c => {
                let invoiceField = c.fields.find(f => f.name === "transaction_id");
                return `${c.lastName}${c.firstName}${c.email}${invoiceField ? invoiceField.value : ''}`.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            });
            if (this.state.company?.id === 1) {
                const searchedProducts = this.state.customers?.filter(c => {
                    let field = c.fields.find(f => f.name === "product");
                    let value = field ? field.value : "Affiliation 2021";

                    let field2 = c.fields.find(f => f.name === "company_name");
                    let value2 = field2 ? field2.value : undefined;

                    return value.toLowerCase().indexOf(search.toLowerCase()) >= 0 || (value2 !== undefined && value2.toLowerCase().indexOf(search.toLowerCase()) >= 0);
                });
                searchedCustomers = searchedCustomers ? (searchedProducts ? Array.from(new Set([...searchedCustomers, ...searchedProducts])) : searchedCustomers) : (searchedProducts ? searchedProducts : undefined);
            }
            this.setState({ searchedCustomers, search });
        }
    };

    /**
     * Change the selected year
     * @param date the selected date
     */
    onChangeYear = (date: Moment | null) => {
        this.setState({ year: date?.startOf('year') ?? moment().startOf('year') });
    };

    getReport = () => {
        Network.downloadYearReport(this.state.year.year()).then(data => {
            this.setState({ customersToPrint: data }, () => {
                this.exportToCSV(this.state.customersToPrint, this.state.year.year().toString() + "-terraxis-report");
            });
        });
    };

    getReportSCCM = () => {
        Network.downloadYearReportSCCM(this.state.year.year()).then(data => {
            let newData = data.map((c: any) => {
                for (var prop in c) {
                    if (c[prop] === "undefined") c[prop] = "";
                }
                return c;
            });
            this.setState({ customersToPrint: newData }, () => {
                this.exportToCSV(this.state.customersToPrint, this.state.year.year().toString() + "-skiclubcransmontana-report", "sccm");
            });
        });
    };

    exportToCSV = (csvData: any, fileName: string, company = "terraxis") => {
        let ws = XLSX.utils.json_to_sheet(csvData);
        var wscols = undefined;
        if (company === "sccm") {
            wscols = [
                { wch: 32 }, { wch: 20 }, { wch: 20 }, { wch: 17 },
                { wch: 14 }, { wch: 12 }, { wch: 11 }, { wch: 20 },
                { wch: 60 }, { wch: 16 }, { wch: 16 }, { wch: 8 },
                { wch: 20 }, { wch: 9 }, { wch: 13 }, { wch: 20 },
                { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 17 }
            ];
        } else {
            wscols = [
                { wch: 20 }, { wch: 20 }, { wch: 16 }, { wch: 12 },
                { wch: 12 }, { wch: 12 }, { wch: 22 }, { wch: 10 },
                { wch: 6 }, { wch: 16 }, { wch: 16 }, { wch: 27 }, { wch: 13 }, { wch: 11 }, { wch: 13 }
            ];
        }
        if (ws) {
            ws['!cols'] = wscols;
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        } else {
            Modal.warning({
                title: 'Aucun paiement',
                content: `Aucun paiement disponible pour ${moment(this.state.year).format("MMMM YYYY")}`,
            });
        }
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { addCustomer, fields } = this.state;
        return (
            <>
                <Typography.Title level={3}>

                </Typography.Title>

                <div>
                    <div className="backoffice-administration-header">
                        <div>
                            <Input.Search
                                id="inputSearch"
                                placeholder={formatMessage({ id: "administration.search" })}
                                className="backoffice-administration-search"
                                onChange={(e) => this.onSearch(e.target.value)}
                                allowClear />

                            {
                                this.state.company?.id !== 2 &&
                                <Button shape="circle" style={{ margin: "10px 0 10px 5px" }} onClick={() => this.setState({ modalEdit: true, addCustomer: { id: -1, fields: [] } })} >
                                    <PlusOutlined />
                                </Button>
                            }
                        </div>
                        <div>
                            <DatePicker.YearPicker
                                className="backoffice-administration-monthpicker"
                                placeholder={formatMessage({ id: "administration.monthpicker" })}
                                format="YYYY"
                                value={this.state.year}
                                onChange={this.onChangeYear}
                                allowClear />
                            <Button
                                onClick={this.state.company?.id === 2 ? this.getReportSCCM : this.getReport}
                            >
                                {formatMessage({ id: "administration.report" })}
                            </Button>
                        </div>
                    </div>
                </div>
                <Table
                    loading={this.state.loading}
                    columns={(this.state.company && this.state.company.id === 2) ? this.columnsSkiClubCM() : this.columns()}
                    scroll={{ x: 1300 }}
                    expandable={{
                        expandedRowRender: (record) => (
                            (this.state.company && this.state.company.id === 2) ? this.userModalSckiClubCM(record) : this.userModal(record)
                        )
                    }}
                    dataSource={this.state.searchedCustomers ?? this.state.customers}
                    rowKey={(c: BackofficeCustomer) => `backoffice-administration-customer-${c.id}`}
                    pagination={{
                        defaultPageSize: 12,
                        pageSizeOptions: ["12", "30", "90", "200"],
                        showSizeChanger: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} ventes`
                    }} />
                <Modal
                    title={addCustomer && addCustomer.id ? formatMessage({ id: "administration.modifyEntry" }) : formatMessage({ id: "administration.addEntry" })}
                    visible={this.state.modalEdit}
                    okText={addCustomer && addCustomer.id ? formatMessage({ id: "administration.modifyEntryValidate" }) : formatMessage({ id: "administration.addEntryValidate" })}
                    okButtonProps={{ loading: this.state.loadingAdd }}
                    cancelButtonProps={{ loading: this.state.loadingAdd }}
                    onOk={() => {
                        if (addCustomer) {
                            this.setState({ loadingAdd: true });
                            Network.saveCustomer(addCustomer).then(
                                response => {
                                    alert(addCustomer && addCustomer.id ? formatMessage({ id: "administration.modifyOk" }) : formatMessage({ id: "administration.addOk" }), "success");
                                    if (!response.error) {
                                        let newCustomers = cloneDeep(this.state.customers);
                                        if (newCustomers) {
                                            let customerToChangeId = newCustomers.findIndex(c => c.id === response.id);
                                            if (customerToChangeId !== -1) {
                                                newCustomers[customerToChangeId] = response;
                                            } else {
                                                newCustomers.unshift(response);
                                            }
                                        } else {
                                            newCustomers = [response];
                                        }
                                        this.setState({ customers: newCustomers, loadingAdd: false, modalEdit: false, addCustomer: undefined });
                                    } else {
                                        alert(addCustomer && addCustomer.id ? formatMessage({ id: "administration.modifyError" }) : formatMessage({ id: "administration.addError" }), "error");
                                        this.setState({ loadingAdd: false });

                                    }

                                    // this.refresh();
                                },
                                error => {
                                    alert(addCustomer && addCustomer.id ? formatMessage({ id: "administration.modifyError" }) : formatMessage({ id: "administration.addError" }), "error");
                                    this.setState({ loadingAdd: false });
                                },
                            );
                        }
                    }}
                    onCancel={() => this.setState({ modalEdit: false, addCustomer: undefined })}
                    width={"700px"}>
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                            {formatMessage({ id: "administration.email" })}
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                            <Input value={addCustomer?.email}
                                onChange={e => {
                                    addCustomer && (addCustomer.email = e.target.value);
                                    this.setState({ addCustomer });
                                }}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                            {formatMessage({ id: "administration.firstName" })}
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                            <Input value={addCustomer?.firstName}
                                onChange={e => {
                                    addCustomer && (addCustomer.firstName = e.target.value);
                                    this.setState({ addCustomer });
                                }} />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                            {formatMessage({ id: "administration.name" })}
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                            <Input value={addCustomer?.lastName}
                                onChange={e => {
                                    addCustomer && (addCustomer.lastName = e.target.value);
                                    this.setState({ addCustomer });
                                }} />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                            {formatMessage({ id: "administration.lang" })}
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 16 }}>

                            <Select
                                style={{ width: "100%" }}
                                // placeholder={formatMessage({ id: 'registration.company.country' })}
                                className="form-input form-input-select"
                                onChange={(e) => {
                                    addCustomer && (addCustomer.lang = e.toString());
                                    this.setState({ addCustomer });
                                }}
                                value={addCustomer ? addCustomer.lang : "fr"}
                            >
                                <Select.Option key={`product-fr`} value={"fr"}>
                                    fr
                                </Select.Option>
                                <Select.Option key={`product-en`} value={"en"}>
                                    en
                                </Select.Option>
                            </Select>
                        </Col>
                        {
                            fields && fields.map(f => {
                                let fieldValue = addCustomer && addCustomer.id && addCustomer.fields && addCustomer.fields.find(fv => fv.name === f.name);
                                if (f.name === "product") {
                                    return (
                                        <Fragment key={`field-group-${f.id}`}>
                                            <Col key={`field-display-name-${f.id}`} xs={{ span: 24 }} sm={{ span: 8 }}>
                                                {f.displayName}
                                            </Col>
                                            <Col key={`field-value-${f.id}`} xs={{ span: 24 }} sm={{ span: 16 }}>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    // placeholder={formatMessage({ id: 'registration.company.country' })}
                                                    className="form-input form-input-select"
                                                    onChange={(e) => this.changeSelectStringFieldValue(e?.toString(), f.name)}
                                                    value={fieldValue ? fieldValue.value : "Affiliation 2025"}
                                                >
                                                    <Select.Option key={`product-2025`} value={"Affiliation 2025"}>
                                                        Affiliation 2025
                                                    </Select.Option>
                                                    <Select.Option key={`product-2024`} value={"Affiliation 2024"}>
                                                        Affiliation 2024
                                                    </Select.Option>
                                                    <Select.Option key={`product-2023`} value={"Affiliation 2023"}>
                                                        Affiliation 2023
                                                    </Select.Option>
                                                    <Select.Option key={`product-2022`} value={"Affiliation 2022"}>
                                                        Affiliation 2022
                                                    </Select.Option>
                                                    <Select.Option key={`product-2021`} value={"Affiliation 2021"}>
                                                        Affiliation 2021
                                                    </Select.Option>
                                                </Select>
                                                {/* <Input onChange={(e) => this.changeFieldValue(e, f.name)} value={fieldValue && fieldValue.value ? fieldValue.value : addCustomer && addCustomer.id ? "Affiliation 2021" : ""} /> */}
                                            </Col>
                                        </Fragment>
                                    );
                                } else if (f.name === "canton") {
                                    return (
                                        <Fragment key={`field-group-${f.id}`}>
                                            <Col key={`field-display-name-${f.id}`} xs={{ span: 24 }} sm={{ span: 8 }}>
                                                {f.displayName}
                                            </Col>
                                            <Col key={`field-value-${f.id}`} xs={{ span: 24 }} sm={{ span: 16 }}>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder={formatMessage({ id: 'administration.canton' })}
                                                    showSearch
                                                    className="form-input form-input-select"
                                                    value={fieldValue ? toInteger(fieldValue.value) : undefined}
                                                    onChange={e => this.changeCFieldValue(e, f.name)}
                                                    filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                                    options={this.state.canton ? this.state.canton.map(c=>({label: c.translations[0].name, value: c.id})) : []}
                                                />
                                            </Col>
                                        </Fragment>
                                    );
                                } else if (f.name === "country") {
                                    return (
                                        <Fragment key={`field-group-${f.id}`}>
                                            <Col key={`field-display-name-${f.id}`} xs={{ span: 24 }} sm={{ span: 8 }}>
                                                {f.displayName}
                                            </Col>
                                            <Col key={`field-value-${f.id}`} xs={{ span: 24 }} sm={{ span: 16 }}>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder={formatMessage({ id: 'administration.country' })}
                                                    showSearch
                                                    value={fieldValue ? toInteger(fieldValue.value) : undefined}
                                                    onChange={e => this.changeCFieldValue(e, f.name)}
                                                    className="form-input form-input-select"
                                                    filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                                    options={this.state.countries != null ? this.state.countries.map(c=>({label: c.translations[0].name, value: c.id})): []}
                                                />
                                            </Col>
                                        </Fragment>
                                    );
                                } else {
                                    return (
                                        <Fragment key={`field-group-${f.id}`}>
                                            <Col key={`field-display-name-${f.id}`} xs={{ span: 24 }} sm={{ span: 8 }}>
                                                {f.displayName}
                                            </Col>
                                            <Col key={`field-value-${f.id}`} xs={{ span: 24 }} sm={{ span: 16 }}>
                                                <Input onChange={(e) => this.changeFieldValue(e, f.name)} value={fieldValue ? fieldValue.value : ""} />
                                            </Col>
                                        </Fragment>
                                    );
                                }
                            })
                        }
                    </Row>
                </Modal>
            </>
        );
    }
    changeFieldValue = (e: React.ChangeEvent<HTMLInputElement>, name: string | undefined): void => {
        let { addCustomer } = this.state;

        let fieldExist = addCustomer?.fields.findIndex(f => f.name === name);

        if (fieldExist === -1 || fieldExist === undefined) {
            addCustomer?.fields.push({ id: -1, value: e.target.value, name: name });
        } else {
            addCustomer && (addCustomer.fields[fieldExist].value = e.target.value);
        }

        this.setState({ addCustomer });
    };

    changeCFieldValue = (e: number, name: string | undefined): void => {
        let { addCustomer } = this.state;

        let fieldExist = addCustomer?.fields.findIndex(f => f.name === name);

        if (fieldExist === -1 || fieldExist === undefined) {
            addCustomer?.fields.push({ id: -1, value: e ? e.toFixed() : "", name: name });
        } else {
            addCustomer && (addCustomer.fields[fieldExist].value = e ? e.toFixed() : "");
        }

        this.setState({ addCustomer });
    };

    changeSelectStringFieldValue = (e: string | undefined, name: string | undefined): void => {
        if (e) {
            let { addCustomer } = this.state;

            let fieldExist = addCustomer?.fields.findIndex(f => f.name === name);

            if (fieldExist === -1 || fieldExist === undefined) {
                addCustomer?.fields.push({ id: -1, value: e ? e : "", name: name });
            } else {
                addCustomer && (addCustomer.fields[fieldExist].value = e ? e : "");
            }

            this.setState({ addCustomer });
        }
    };
}

export default withRouter(injectIntl(Administration));