import React from 'react';
import { Form, Input, Button, Checkbox, Select, Col, Row, Spin } from 'antd';
// import { Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Country, Canton, RegistrationBobyRequest, RouterProps, CustomerBodyRequest, Customer } from '../../utils/types';
import Network from '../../utils/network';
import '../../css/registration.css';
import PaymentModal from './paymentModal';
import { TERRAXISID, CURRENCY, AMOUNT, ACCENTCOLOR, BACKGROUNDCOLOR, AMOUNTCH } from '../../utils/constants';
import { withRouter } from '../../utils/withRouter';

interface IProps {
};

interface State {
    countries: Country[] | undefined;
    canton: Canton[] | undefined;
    values: RegistrationBobyRequest | undefined;
    selectedCountry: Country | undefined;
    customerId: number | undefined;
    phoneCode: string;
    customer?: Customer;
    isError: boolean;
}

type Props = IProps & WrappedComponentProps & RouterProps;

class Registration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { formatMessage } = this.props.intl;
        this.state = {
            countries: undefined,
            canton: undefined,
            values: undefined,
            selectedCountry: undefined,
            customerId: undefined,
            phoneCode: formatMessage({ id: 'registration.person.phone' }),
            isError: false,
        };
    }

    componentDidMount() {
        // document.title = "Terraxis - Registration";
        let lang = this.props.router.params.lang;
        Network.getCountriesList(lang).then(data => this.setState({ countries: data.data }));
        Network.getCantonList(lang).then(data => this.setState({ canton: data.data }));

        const search = this.props.router.location.search;
        let id = new URLSearchParams(search).get("id");
        let token = new URLSearchParams(search).get("token");
        id && token && Network.getCustomer(parseInt(id), token).then(
            response => {
                if (!response.data.error) {
                    this.setState({ customer: response.data }, () => console.log("YEAH", this.state.customer));
                } else {
                    this.setState({ isError: true });
                }
            },
            error => console.log("ERROR", error)
        )
    }

    onSelectCountry = (id: number) => {
        const { formatMessage } = this.props.intl;
        const selectedCountry = this.state.countries?.find(c => c.id === id);
        let phoneCode = formatMessage({ id: 'registration.person.phone' });
        if (selectedCountry?.phoneCode) {
            phoneCode = selectedCountry.phoneCode;
        }
        this.setState({ selectedCountry, phoneCode });
    }

    onFinish = (values: any) => {
        const { formatMessage } = this.props.intl;
        let lang = this.props.router.params.lang;
        const hostname = window.location.hostname;
        const port = window.location.port;
        const RETURNSUCCESS = "https://" + hostname + ":" + port + "/" + formatMessage({ id: 'lang' }) + "/pay_success";
        const RETURNABORDT = "https://" + hostname + ":" + port + "/" + formatMessage({ id: 'lang' }) + "/registration";
        const RETURNERROR = "https://" + hostname + ":" + port + "/" + formatMessage({ id: 'lang' }) + "/pay_error";
        localStorage.setItem('companyId', TERRAXISID.toString());
        localStorage.setItem('email', values.email);
        localStorage.setItem('firstName', values.firstName);
        localStorage.setItem('lastName', values.lastName);
        localStorage.setItem('city', values.city);
        localStorage.setItem('company_address', values.company_address);
        localStorage.setItem('company_name', values.company_name);
        localStorage.setItem('country', values.country);
        localStorage.setItem('product', values.product);
        localStorage.setItem('current_affiliate', values.current_affiliate);
        localStorage.setItem('phone', values.phone);
        localStorage.setItem('postcode', values.postcode);
        localStorage.setItem('canton', values.canton);
        let infoValues: RegistrationBobyRequest = {
            companyId: TERRAXISID,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            language: lang,
            currency: CURRENCY,
            amount: values.country === 220 ? Math.trunc(AMOUNTCH * 100) : Math.trunc(AMOUNT * 100),
            typeQuery: "initialize",
            returnSuccess: RETURNSUCCESS,
            returnAbort: RETURNABORDT,
            returnError: RETURNERROR,
            accentColor: ACCENTCOLOR,
            backgroundColor: BACKGROUNDCOLOR,
            // billingDescriptor: TERRAXISNAME,
            description: formatMessage({ id: 'affiliation.certificate' }),
            paymentMethods: ["credit_card"/*, "twint"*/],

            fieldsValues: {
                city: values.city,
                company_address: values.company_address,
                company_name: values.company_name,
                country: values.country,
                current_affiliate: values.current_affiliate,
                phone: values.phone,
                postcode: values.postcode,
                canton: values.canton,
                product: values.product
            }
        }
        values = infoValues;
        this.setState({ values }, () => console.log(this.state.values));
    };

    onClickOnlinePay = () => {
        if (this.state.values) {
            Network.register(this.state.values).then(data => {
                if (data.data.result?.checkout_url) {
                    window.location.href = data.data.result.checkout_url;
                } else {
                    console.log("\nError request\n", data);
                }
            });
        }
    }

    onClickBankTransferPayed = () => {
        if (this.state.values) {
            let infoValues: CustomerBodyRequest = {
                typeQuery: "createCustomer",
                lang: this.state.values.language,
                companyId: TERRAXISID,
                email: this.state.values.email,
                firstName: this.state.values.firstName,
                lastName: this.state.values.lastName,

                fieldsValues: {
                    ...this.state.values.fieldsValues,
                }
            }
            Network.createCustomer(infoValues).then(data => {
                if (data.data.response === "ok") {
                    console.log(data.data);
                    Network.sendIbanToCustomer("sendIban", this.props.router.params.lang, data.data.customerId).then(data => {
                        if (data.data.result === "ibanSent") {
                            console.log("ibanSent");
                            this.props.router.navigate(`/${this.props.router.params.lang}/iban_success`);
                            // window.location.href = '/' + formatMessage({ id: 'lang' }) + '/iban_success';
                        } else if (data.data.result === "ibanError") {
                            console.warn("The email with the IBAN didn't get through.")
                        } else {
                            console.error("Unkown error! The email with the IBAN didn't get through.")
                        }
                    });
                }
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { Option } = Select;
        const { customer, isError } = this.state;

        const search = this.props.router.location.search;
        const id = new URLSearchParams(search).get("id");

        const company_name_field = customer?.fields.find(f => f.name === "company_name");
        let company_name = company_name_field ? company_name_field.value : undefined;
        const company_address_field = customer?.fields.find(f => f.name === "company_address");
        let company_address = company_address_field ? company_address_field.value : undefined;
        const postcode_field = customer?.fields.find(f => f.name === "postcode");
        let postcode = postcode_field ? postcode_field.value : undefined;
        const city_field = customer?.fields.find(f => f.name === "city");
        let city = city_field ? city_field.value : undefined;
        const phone_field = customer?.fields.find(f => f.name === "phone");
        let phone = phone_field ? phone_field.value : undefined;
        const country_field = customer?.fields.find(f => f.name === "country");
        let country = country_field ? country_field.value : undefined;
        const canton_field = customer?.fields.find(f => f.name === "canton");
        let canton = canton_field ? canton_field.value : undefined;
        const current_affiliate_field = customer?.fields.find(f => f.name === "current_affiliate");
        let current_affiliate = current_affiliate_field ? current_affiliate_field.value : undefined;

        const loading = !customer;

        return (
            <>
                {
                    id && loading && !isError ?
                        <div style={{ width: "100%", textAlign: "center", marginTop: "100px" }}>
                            <Spin />
                        </div>
                        :
                        <Form
                            onFinish={this.onFinish}
                            name="basic"
                            className="form"
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <h1 className="form-main-title">{formatMessage({ id: 'registration.company.mainTitle' })}</h1>
                                    {/* <p className="form-description">{formatMessage({ id: 'registration.company.description' })}</p> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <h2 className="form-title">{formatMessage({ id: 'registration.company.product' })}</h2>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="product"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' })
                                        }]}
                                        initialValue={"Affiliation 2025"}
                                    >

                                        <Select
                                            // placeholder={formatMessage({ id: 'registration.company.country' })}
                                            className="form-input form-input-select"
                                        >
                                            <Option key={`product-2024`} value={"Affiliation 2025"}>
                                                Affiliation 2025
                                            </Option>
                                            <Option key={`product-2024`} value={"Affiliation 2024"}>
                                                Affiliation 2024
                                            </Option>
                                            {/* <Option key={`product-2023`} disabled value={"Affiliation 2023"}>
                                                Affiliation 2023
                                            </Option> */}
                                            {/* <Option key={`product-2022`} disabled value={"Affiliation 2022"}>
                                                Affiliation 2022
                                            </Option> */}
                                            {/* <Option title={formatMessage({ id: 'registration.company.product.error' })} disabled key={`product-2021`} value={"Affiliation 2021"}>
                                                Affiliation 2021
                                            </Option> */}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <h2 className="form-title">{formatMessage({ id: 'registration.company.title' })}</h2>
                                </Col>
                            </Row>

                            <Row gutter={24} style={{ marginTop: "20px" }}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="company_name"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={company_name}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.company.name' })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="company_address"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={company_address}

                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.company.address' })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="postcode"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={postcode}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.company.zipcode' })} />
                                    </Form.Item>
                                </Col>


                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="city"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={city}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.company.city' })} />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="country"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' })
                                        }]}
                                        initialValue={country && parseInt(country)}
                                    >

                                        <Select
                                            placeholder={formatMessage({ id: 'registration.company.country' })}
                                            allowClear
                                            showSearch
                                            onChange={this.onSelectCountry}
                                            className="form-input form-input-select"
                                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                            options={this.state.countries?.map(c=>({label: c.translations[0].name, value: c.id}))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues.country !== currentValues.country}
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('country') === 220 ? (
                                                <Form.Item
                                                    name="canton"
                                                    rules={[{
                                                        required: true,
                                                        message: formatMessage({ id: 'registration.input.error' })
                                                    }]}
                                                    initialValue={canton && parseInt(canton)}
                                                >
                                                    <Select
                                                        placeholder={formatMessage({ id: 'registration.company.canton' })}
                                                        allowClear
                                                        showSearch
                                                        className="form-input form-input-select"
                                                        filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                                        options={this.state.canton?.map(c=>({label: c.translations[0].name, value: c.id}))}
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <h2 className="form-title" >{formatMessage({ id: 'registration.person.title' })}</h2>
                                </Col>
                            </Row>
                            <Row gutter={24} style={{ marginTop: "20px" }}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="lastName"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={customer?.lastName}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.person.lastname' })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="firstName"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={customer?.firstName}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.person.firstname' })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="phone"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={phone}
                                    >
                                        <Input className="form-input" placeholder={this.state.phoneCode} />
                                        {/* <Input className="form-input" placeholder={formatMessage({ id: 'registration.person.phone' })} onChange={e => this.setState({ phoneCode: e.target.value})} value={this.state.phoneCode}/> */}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: formatMessage({ id: 'registration.input.error' }),
                                            }, {
                                                type: "email",
                                                message: formatMessage({ id: 'registration.email.error' }),
                                            }
                                        ]}
                                        initialValue={customer?.email}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.person.email' })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="current_affiliate"
                                        rules={[{
                                            // required: true,
                                            message: formatMessage({ id: 'registration.input.error' }),
                                        }]}
                                        initialValue={current_affiliate}
                                    >
                                        <Input className="form-input" placeholder={formatMessage({ id: 'registration.person.currentregulation' })} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="remember"
                                        rules={[
                                            {
                                                validator: (_, value) => value ? Promise.resolve() : Promise.reject(formatMessage({ id: 'registration.rules.accept' }))
                                            },
                                        ]}
                                        valuePropName="checked">
                                        <Checkbox><span dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'registration.rules' }) }} /></Checkbox>

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="statement"
                                        rules={[
                                            {
                                                validator: (_, value) => value ? Promise.resolve() : Promise.reject(formatMessage({ id: 'registration.rules.accept' }))
                                            },
                                        ]}
                                        valuePropName="checked">
                                        <Checkbox>{formatMessage({ id: 'registration.rules.financial' })}</Checkbox>

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item className="form-button-parent">
                                        <Button className="form-button" type="primary" htmlType="submit">
                                            {formatMessage({ id: 'send' })}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form >

                }


                <PaymentModal
                    open={this.state.values !== undefined}
                    isSwiss={this.state.values?.fieldsValues.country === 220}
                    onCancel={() => this.setState({ values: undefined })}
                    onClickOnlinePay={this.onClickOnlinePay}
                    onClickBankTransferPayed={this.onClickBankTransferPayed} />
            </>
        );
    }
}

export default withRouter(injectIntl(Registration));